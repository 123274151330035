@import "src/styles/variables";

.on-boarding {
	position: relative;
	.layer-image__wrapper {
		position: relative;
		.bg-image {
			height: 100vh;
		}
		
		.logo {
			height: 8rem;
			position: absolute;
			top: 40vh;
			left: 25vh;
			img{
				height: 80%;

			}
			p{
				color: rgba($color: #ffffff, $alpha: 0.75);
				font-size: 20px;
				text-align: center;
				margin-top: 0.5rem;
			}
		}

		.layer {
			position: absolute;
			top: 0;
			left: -17px;
			height: 100vh;
		}

		.outer-layer {
			left: 38px;
			z-index: -1;
		}
	}

	.move-left-to-right {
		animation: move-left-to-right 1s;
	}

	.move-right-to-left {
		animation: move-right-to-left 1s;
        animation-fill-mode: forwards;
	}
	.on-boarding__content {
		padding: 2rem 0;
		position: absolute;
		left: 50%;
        height: 100vh;
        overflow-y: scroll;
		width: 100%;
	}
	.fade-in {
		animation: fade-in 0.5s;
	}

	.fade-out {
		animation: fade-out 0.5s;
	}
}


@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 0;
	}
	to {
		opacity: 0;
	}
}


@keyframes move-left-to-right {
	from {
		margin-left: -55vw;
	}

	to {
		margin-left: 0vw;
	}
}

@keyframes move-right-to-left {
	from {
		margin-left: 0vw;
	}

	to {
		margin-left: -55vw;
	}
}
