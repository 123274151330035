.kyc-document-modal {
    top: 1rem !important;
    width: 100vw !important;

    .ant-modal-body {

        .kyc-document-modal__header {
            display: flex;
            align-items: center;
            position: relative;

            .kyc-document-modal--title {
                margin: 0 1rem 0 0;
                font-size: 28px;
                font-weight: bold;
            }

            .kyc-document-modal--accept,
            .kyc-document-modal--reject {
                width: 20px;
                height: 20px;
                cursor: pointer;
                margin: 0 0.5rem;
            }

            .kyc-document-modal--toggle {
                cursor: pointer;
                position: absolute;
                right: 2rem;
                margin: 0;
                font-size: 16px;
                color: #707070;
            }

            .kyc-document-modal__header--accordion {
                padding: 1rem 0;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                position: absolute;
                top: 43px;
                left: 0;
                background: #fff;
                box-shadow: 0px 10px 20px #1C416D0D;

                &.panel-open {
                    animation: kycHeaderPanelSlideDown forwards 500ms;
                }

                &.panel-closed {
                    animation: kycHeaderPanelSlideUp forwards 500ms;
                }

                .accordion-content {
                    width: 175px;
                    margin: 0 0rem;

                    .accordion-content--title {
                        margin: 0;
                        font-size: 14px;
                        color: #707070;
                    }

                    .accordion-content--value {
                        font-size: 16px;
                        font-weight: 500;
                        color: #303030;
                    }
                }
            }
        }

        .kyc-document__body {
            margin: 2rem 0 0;

            .kyc-document--video {

                video {
                    // width: 100%;
                    margin: auto;
                    display: block;
                }

                .kyc-document--video-code {
                    margin: 1rem 0;
                    font-size: 18px;

                    span {
                        font-weight: bold;
                    }
                }
            }

            .kyc-document--pdf {
                iframe {
                    width: 100%;
                    height: 80vh;
                }
            }
        }
    }

    .ant-modal-footer {
        display: none;
    }
}

@keyframes kycHeaderPanelSlideDown {
    0% {
        top: 0px;
        opacity: 0;
    }

    100% {
        top: 40px;
        opacity: 1;
    }
}

@keyframes kycHeaderPanelSlideUp {
    0% {
        top: 40px;
        opacity: 1;
    }

    100% {
        top: 0;
        opacity: 0;
        z-index: -1;
    }
}