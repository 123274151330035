.page-header__container {
    display: flex;
    justify-content: space-between;

    .page-header__details {
        animation: slideRight 1000ms ease forwards;

        .page-header__details--back {
            margin: .75rem 0;
            font-size: 14px;
            color: #B2B4B5;
            cursor: pointer;

            .anticon {
                margin: 0 .25rem;
            }
        }

        .page-header__details--title {
            margin: 0 0 .5rem 0;
            font-size: 32px;
            line-height: 38px;
            font-weight: bold;
            text-transform: capitalize;

            .page-header__details--title-badge {
                margin: 0 1rem;
                padding: 0 0.75rem 0 0.5rem;
                min-width: 65px;
                height: 28px;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                border-radius: 20px;
                display: inline-flex;
                align-items: center;
                background: #FFA417;

                .icon-subscription::before {
                    margin: 5px;
                }

                &.Platinum {
                    background: #2AB8F5;
                }

                &.Chama {
                    background: #A4CF57;
                }

                &.Silver {
                    background: #002F87;
                }

            }
        }

        .page-header__details--subtitle {
            margin: 0;
            display: flex;
            width: fit-content;
            color: #707070;
            font-size: 16px;
            text-transform: capitalize;

            hr {
                margin: auto 0.75rem;
                height: 5px;
                width: 5px;
                border: none;
                border-radius: 10px;
                background: silver;
                opacity: .2;
            }
        }

        &.subtitle-placeholder {
            .page-header__details--subtitle {
                min-height: 25px;
            }
        }
    }

    .page-header__wallet--container {
        display: flex;
        justify-content: space-between;
        align-items: end;
        animation: slideLeft 1000ms ease forwards;

        .page-header__wallet {
            margin: 0 1rem 0 2rem;
            padding: 0 1rem 0 2rem;
            border-right: 1px solid #b1b3b433;
            background: url("./../../../assets/icons/wallet.svg") no-repeat;
            background-size: 25px 25px;
            background-position: 5px 0;
            font-size: 16px;

            .page-header__wallet-text {
                margin: 0 5px;
                color: #B2B4B5;
            }

            .page-header__wallet-balance {
                margin: 0 5px;
                font-weight: 600;
                color: #303030;
            }
        }

        .page-header__wallet--notification {
            width: 25px;
            height: 25px;
            cursor: pointer;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            &.badge::before {
                content: "\2022";
                color: hsla(9, 100%, 72%, 0.5);
                font-size: 350%;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(30%, -50%);
            }

            &.badge::after {
                content: "\2022";
                color: #FF8872;
                font-size: 200%;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(17%, -43%);
            }
        }
    }

}

@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}