@import "src/styles/variables";

.allocate-manager {
  &__form {
    display: flex;

    &__allocate-btn {
      &.ant-btn {
        height: 32px;
        width: 150px !important;
        border: 0.5px solid $input-border;
        border-radius: 10px;
        margin: 0 0 0 0.5rem;
        
        &:not(:disabled) {
          background: $primary-color;
          color: $white;
        }
      }

      .ant-btn[disabled] {
        height: 32px;
        width: 150px;
        border: 0.5px solid $input-border;
        border-radius: 10px;
        margin: 0 0 0 0.5rem;
      }
    }
  }
  
  .dropdown-field {
    margin-right: 0.5rem;

    .ant-select {
      margin-top: 0;
  
      .ant-select-selector {
        height: 32px !important;
        width: 250px;
      }
  
      .ant-select-arrow {
        top: 45%;
      }

      .ant-select-clear {
        top: 45%;
      }
    }
  }
}