.withdraw-reqs {
    .withdraw-reqs__header {
        padding: 3rem 2rem 1rem;
    }

    .withdraw-reqs__table {
        padding: 0rem 2rem 3rem;

        .withdraw-reqs__table--actions {

            img {
                width: 20px;
                cursor: pointer;

                &:first-child {
                    margin-right: 1rem;
                }
            }
        }
    }
}