.document-viewer {
    width: fit-content;
    height: fit-content;
    position: relative;

    iframe {
        padding: 15px;
        width: 100%;
        background-color: #bfbfbf80;
        border: .5px solid silver;
        border-radius: 10px;
    }

    .document-viewer--details {
        padding: 1rem;
        width: 100%;
        height: 40%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute;
        bottom: 5px;
        left: 0;
        background: #fff;
        border: .5px solid silver;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #303030;
        cursor: pointer;

        img {
            margin: 0 0.5rem;
            width: 15px;
        }

        .document-viewer__actions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .document-viewer--upload {
                // background: #000;
                padding: .5rem;
                display: inline-flex;
                text-align: end;

                .anticon.anticon-upload {
                    padding: .5rem;
                    background-color: #dddddd;
                    border-radius: 100px;
                }
            }
        }
    }

    .document-viewer__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


.kyc--status {
    margin: 0;
    display: inline-block;
    margin-left: auto;
    // padding: 0 1rem;
    text-align: end;
    font-style: italic;
    text-transform: capitalize;
    font-size: 14px;
    display: inline-flex;

    &.pending {
        color: #FFA417;
    }

    &.accepted,
    &.Approved {
        color: #43D5AE;
    }

    &.rejected,
    &.Rejected {
        color: #EA5353;
    }
}