.change-password-modal{
    .ant-modal-content{
      width: 100%;
      border-radius: 0.875rem;
      padding-left: 1.25rem;
      padding-top: 0.625rem;
      .ant-modal-close{
        margin-top: 0.875rem;
        margin-right: 1.875rem;
        &:hover{
          color:#ed3130
        }
      }
    }
    .ant-modal-title{
      font: normal normal bold 24px/29px SF Pro Display;
      letter-spacing: 0px;
      color: #303030;
      opacity: 1;
      
    }
    .ant-modal-header{
      border: none;
      border-radius: 1.875rem;
    }
  
    .ant-modal-body{
      .ant-input{
        width: 90%;
        border-radius: 12px;
      }
      
      .update-password-btn{
        margin-left: 4.375rem;
        width: 60%;
        background-color: #002F87;
        border-radius: 0.9375rem;
        opacity: 1;
        color: #FFF;
      }
      .update-password-btn:disabled{
        color: white;
        opacity: 0.5
      }
      .ant-btn.ant-btn-primary.ant-btn-loading {
        width: 60% !important;
     }
    }
  }