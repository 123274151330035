.customer__container {
  .customer__header {
    padding: 3rem 2rem 1rem;
  }

  .customer__table {
    padding: 0rem 2rem 3rem;

    .filterBtn {
      left: 16.3rem;
      bottom: 3rem;

      width: 86px;
      height: 32px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.5px solid #b2b4b5;
      border-radius: 10px;
      color: black !important;
    }

    .customer-form {
      width: 816px !important;
      height: 189px !important;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      border: 0.5px solid var(--unnamed-color-b2b4b5);
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 20px #1c416d0d;
      border: 0.5px solid #b2b4b5;
      border-radius: 16px;
      opacity: 1;
      display: flex;
    }

    .filter_reset {
      width: 67px;
      height: 32px;
      background: var(--unnamed-color-b2b4b5) 0% 0% no-repeat padding-box;
      background: #b2b4b5 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
    }

    .filter_apply {
      width: 68px;
      height: 32px;
      background: var(--unnamed-color-002f87) 0% 0% no-repeat padding-box;
      background: #002f87 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
    }

    .customer-list-table {
      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-table {
              .ant-table-container {
                .ant-table-content {
                  table {
                    .ant-table-tbody {
                      .ant-table-row {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-popover-content {
  margin-top: 5px;

  .ant-popover-inner {
    border-radius: 16px !important;

    .styled-modal-btn {
      h3 {
        padding-top: 10px;
      }
    }
  }

  .ant-popover-title {
    display: none;
  }

  .ant-popover-inner-content {
    border-radius: 16px !important;
    width: 500px;
    // height: 250px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 0.5px solid var(--unnamed-color-b2b4b5);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #1c416d0d;
    border: 0.5px solid #b2b4b5;

    opacity: 1;
  }

  .dropdown-field {
    margin-bottom: 0 !important;

    .dropdown-field__title {
      padding-left: 22px;
      text-transform: capitalize;
    }

    /* padding-top: 10px; */
    margin-top: 15px;

    .ant-select {
      padding-left: 15px;
    }

    .ant-select-selector {
      /*  */
      overflow: auto;
    }
  }

  Button {
    width: 67px !important;
    height: 32px !important;
    border-radius: 10px !important;
  }

  .customerResetbtn {
    position: relative;
    right: 20% !important;
    top: 30%;
    background: var(--unnamed-color-002f87) 0% 0% no-repeat padding-box !important;
    background: #002f87 0% 0% no-repeat padding-box !important;
  }

  .customerSubmitbtn {
    top: 30%;
    background: var(--unnamed-color-b2b4b5) 0% 0% no-repeat padding-box !important;
    background: #b2b4b5 0% 0% no-repeat padding-box !important;
  }
}

.filterCalc {
  position: absolute;
  top: 91%;
  left: 8%;
}

.filterPopup {
  padding-bottom: 1rem;
}