.alert-modal__container {
  .ant-modal-content {
    padding: 1rem;
    border-radius: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 30px #1c416d0f;

    .ant-modal-header {
      padding-bottom: 0;
      border-radius: 30px;
      border-bottom: none;

      .ant-modal-title {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .ant-modal-body {
      padding: 1rem 24px;
    }

    .ant-modal-footer {
      padding-bottom: 1rem;
      border: none;

      .ant-btn:not(.ant-btn-primary) {
        display: none;
      }
    }

    .ant-modal-close {
      top: 1rem;

      .ant-modal-close-x {
        font-size: 12px;
      }
    }
  }
}

.reject-modal {
  .ant-modal-content {

    .ant-modal-header {
      .ant-modal-title {
        font-size: 24px;
      }
    }

    .withdraw-req__content {
      i {
        margin-right: .5rem;

        &::before {
          color: #FFD12E;
        }
      }
    }

    .ant-modal-footer {
      display: flex;

      .ant-btn {
        width: 50%;
        height: 48px;
        border: none;
        background: #FF8872;
        border-radius: 18px;
        font-size: 16px;

        &::after {
          content: none;
        }
      }

      .ant-btn:not(.ant-btn-primary) {
        background: #B2B4B5;
        color: #fff;
        display: inline;
      }
    }
  }
}