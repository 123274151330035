.wallet__container {
  max-width: 100%;
  height: 170px;
  display: flex;
  height: fit-content;

  .wallet__dynamic {
    margin: 0 1% 0 0;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(150deg, #002f87 40%, #003caa 40.5%);
    border-top: 3px solid #2ab8f5;
    border-radius: 30px;

    @media only screen and (max-width: 840px) {
      width: 100%;
    }

    .wallet__dynamic--balance {
      width: 50%;
      color: #2ab8f5;
      display: flex;
      align-items: center;

      .wallet__dynamic--wallet-icon {
        margin: 1rem;
        padding: 1.25rem;
        border-radius: 100px;
        background-color: #ffffff1a;

        img {
          width: 35px;
          height: 35px;
        }
      }

      .wallet__dynamic--balance--title {
        margin: 0.5rem 0;
        font-size: 16px;
      }

      .wallet__dynamic--balance--value {
        margin: 0;
        font-size: 34px;
        font-weight: bold;
      }

      .ant-skeleton {
        .ant-skeleton-input {
          margin: 0.5rem 0;
        }
      }
    }

    .wallet__dynamic--actions {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 50%;

      button {
        padding: 0 1rem;
        margin: 0.5rem;
        width: 120px;
        height: 48px;
        border-radius: 18px;

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .primary {
        color: #fff;
        background-color: #2ab8f5;
        box-shadow: none;
      }

      .default {
        width: fit-content;
        color: #2ab8f5;
        border: 1px solid #2ab8f5;
        background-color: #2ab8f533;
      }
    }
  }

  .footer {
    .comment {
      height: 500px;
    }
  }

  .wallet__static {
    margin: 0 0 0 1%;
    width: 34%;
    height: 100%;
    border-radius: 30px;
    border: 1px solid #002f87;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 30px 30px #1c416d0f;

    @media only screen and (max-width: 840px) {
      width: 100% !important;
      flex-wrap: wrap;
    }

    .wallet__static--balance {
      color: #002f87;
      width: 100%;
      display: flex;
      align-items: center;

      .wallet__static--wallet-icon {
        margin: 1rem;
        padding: 1.5rem;
        border-radius: 100px;
        background-color: #002f871a;

        img {
          width: 29px;
          height: 30px;
        }
      }

      .wallet__static--balance--title {
        margin: 0.5rem 0;
        font-size: 16px;
        opacity: 0.8;

        .icon-ic-info-24px::before {
          padding: 0.5rem;
          font-size: 12px;
          opacity: 1;
        }
      }

      .wallet__static--balance--value {
        margin: 0;
        font-size: 36px;
        font-weight: bold;
      }

      .ant-skeleton {
        .ant-skeleton-input {
          margin: 0.5rem 0;
        }
      }
    }
  }
}

.input_comment {
  height: 120px !important;
  border-radius: 15px !important;
  margin-top: 8px !important;
}


.wallet-form {
  .ant-radio-group {
    width: 100%;

    .ant-radio-wrapper span:not(.ant-radio):not(.ant-radio-inner) {
      width: 100%;
    }

    .ant-radio-wrapper {
      margin-bottom: 1rem;
      display: flex;
      position: relative;
      align-items: flex-start;

      .ant-radio {
        margin-top: .25rem;
      }

      .ant-col {
        position: static;
      }

      .withdraw-option-bank {

        .option {
          margin-bottom: .5rem;
          font-size: 16px;
        }

        .bank {
          font-size: 14px;
          color: #707070;
          text-align: left;
        }

        .icon-cover {
          background-color: hsla(36, 100%, 55%, 0.1);
          border-radius: 100px;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 25px;
          }
        }

        .icon-cover,
        .icon {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translate(0, -50%);
        }
      }

      .input-cover {
        margin: .5rem 0;
        height: 50px;
      }

      .mpesa-option {
        margin-right: .25rem;
        font-size: 16px;
        display: flex;
        justify-content: space-between;

        img {
          height: 25px;
        }
      }
    }
  }
}


.ant-modal.ui-modal.withdraw-req__confirmation {

  .title-text {
    padding-bottom: 1rem;
  }

  .otp__container {
    margin-bottom: 2rem;

		.resend__wrapper {
			margin-top: 1rem;
			display: flex;
			color: #b2b4b5;
			justify-content: flex-end;

			&.disable-resend {
				cursor: not-allowed;
			}
		}
	}

	.otp__wrapper {
		input {
			width: 3rem !important;
			border: none;
			border-bottom: 1px solid #b2b4b5;

			&:focus-visible {
				outline: none !important;
			}
		}
	}

  .ant-modal-content {
    padding: 1rem 2rem;

    .ant-modal-body {
      padding: 2rem 1rem;
      font-size: 18px;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      border: none;

      .ant-btn {
        width: 50%;
      }

      .ant-btn:not(.ant-btn-primary) {
        border: 1px solid #B2B4B5;
        margin: 0;
        height: 40px;
        display: inline-block;
        border-radius: 12px;
        color: #B2B4B5 !important;
      }
    }
  }
}

.bank-details--add {
  cursor: pointer;
  color: #2ab8f5;

  &:hover {
    text-decoration: underline;
  }
}