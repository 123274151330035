$icomoon-font-family: "Vuka-icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-vuka-full-logo-path1: "\e900";
$icon-vuka-full-logo-path2: "\e901";
$icon-vuka-full-logo-path3: "\e902";
$icon-vuka-full-logo-path4: "\e903";
$icon-vuka-full-logo-path5: "\e904";
$icon-vuka-full-logo-path6: "\e905";
$icon-vuka-full-logo-path7: "\e906";
$icon-vuka-full-logo-path8: "\e907";
$icon-vuka-full-logo-path9: "\e908";
$icon-vuka-full-logo-path10: "\e909";
$icon-vuka-full-logo-path11: "\e90a";
$icon-vuka-full-logo-path12: "\e90b";
$icon-vuka-full-logo-path13: "\e90c";
$icon-vuka-full-logo-path14: "\e90d";
$icon-vuka-full-logo-path15: "\e90e";
$icon-vuka-full-logo-path16: "\e90f";
$icon-vuka-full-logo-path17: "\e910";
$icon-vuka-full-logo-path18: "\e911";
$icon-vuka-full-logo-path19: "\e912";
$icon-vuka-full-logo-path20: "\e913";
$icon-vuka-full-logo-path21: "\e914";
$icon-vuka-full-logo-path22: "\e915";
$icon-vuka-full-logo-path23: "\e916";
$icon-vuka-full-logo-path24: "\e917";
$icon-vuka-full-logo-path25: "\e918";
$icon-vuka-full-logo-path26: "\e919";
$icon-vuka-full-logo-path27: "\e91a";
$icon-vuka-full-logo-path28: "\e91b";
$icon-vuka-full-logo-path29: "\e91c";
$icon-vuka-full-logo-path30: "\e91d";
$icon-vuka-logo-dark-path1: "\e91e";
$icon-vuka-logo-dark-path2: "\e91f";
$icon-vuka-logo-dark-path3: "\e920";
$icon-vuka-logo-dark-path4: "\e921";
$icon-vuka-logo-dark-path5: "\e922";
$icon-vuka-logo-light-path1: "\e923";
$icon-vuka-logo-light-path2: "\e924";
$icon-vuka-logo-light-path3: "\e925";
$icon-vuka-logo-light-path4: "\e926";
$icon-vuka-logo-light-path5: "\e927";
$icon-bank-details1-path1: "\e928";
$icon-bank-details1-path2: "\e929";
$icon-beneficary-path1: "\e92a";
$icon-beneficary-path2: "\e92b";
$icon-collapse-arrow-path1: "\e92c";
$icon-collapse-arrow-path2: "\e92d";
$icon-collapse-arrow-path3: "\e92e";
$icon-dynamic-wallet---white-path1: "\e92f";
$icon-dynamic-wallet---white-path2: "\e930";
$icon-dynamic-wallet---white-path3: "\e931";
$icon-dynamic-wallet---light-blue-path1: "\e932";
$icon-dynamic-wallet---light-blue-path2: "\e933";
$icon-dynamic-wallet---light-blue-path3: "\e934";
$icon-emergency-contact-path1: "\e935";
$icon-emergency-contact-path2: "\e936";
$icon-expand-arrow-path1: "\e937";
$icon-expand-arrow-path2: "\e938";
$icon-expand-arrow-path3: "\e939";
$icon-faq-selected-path1: "\e93a";
$icon-faq-selected-path2: "\e93b";
$icon-faq-path1: "\e93c";
$icon-faq-path2: "\e93d";
$icon-holdings-selected: "\e93e";
$icon-holdings: "\e93f";
$icon-home-selected-path1: "\e940";
$icon-home-selected-path2: "\e941";
$icon-home-path1: "\e942";
$icon-home-path2: "\e943";
$icon-notificaction-path1: "\e944";
$icon-notificaction-path2: "\e945";
$icon-profile-selected-path1: "\e946";
$icon-profile-selected-path2: "\e947";
$icon-profile-selected-path3: "\e948";
$icon-profile1-path1: "\e949";
$icon-profile1-path2: "\e94a";
$icon-profile1-path3: "\e94b";
$icon-refer-selected-path1: "\e94c";
$icon-refer-selected-path2: "\e94d";
$icon-refer-selected-path3: "\e94e";
$icon-refer-selected-path4: "\e94f";
$icon-refer-path1: "\e950";
$icon-refer-path2: "\e951";
$icon-refer-path3: "\e952";
$icon-refer-path4: "\e953";
$icon-report-selected-path1: "\e954";
$icon-report-selected-path2: "\e955";
$icon-report-path1: "\e956";
$icon-report-path2: "\e957";
$icon-start-video-kyc: "\e958";
$icon-static-wallet---blue-path1: "\e959";
$icon-static-wallet---blue-path2: "\e95a";
$icon-static-wallet---white-path1: "\e95b";
$icon-static-wallet---white-path2: "\e95c";
$icon-stop-video-kyc-path1: "\e95d";
$icon-stop-video-kyc-path2: "\e95e";
$icon-stop-video-kyc-path3: "\e95f";
$icon-support-selected: "\e960";
$icon-support: "\e961";
$icon-total-investment-path1: "\e962";
$icon-total-investment-path2: "\e963";
$icon-total-investment-path3: "\e964";
$icon-total-investment-path4: "\e965";
$icon-wallet-selected-path1: "\e966";
$icon-wallet-selected-path2: "\e967";
$icon-wallet-selected-path3: "\e968";
$icon-wallet-path1: "\e969";
$icon-wallet-path2: "\e96a";
$icon-wallet-path3: "\e96b";
$icon-alert: "\e96c";
$icon-back: "\e96d";
$icon-bank-details: "\e96e";
$icon-close: "\e96f";
$icon-collapse: "\e970";
$icon-date-range: "\e971";
$icon-expand: "\e972";
$icon-ic-info-24px: "\e973";
$icon-mail: "\e974";
$icon-message: "\e975";
$icon-password: "\e976";
$icon-phone: "\e977";
$icon-proceed: "\e978";
$icon-profile: "\e979";
$icon-refresh-ccw: "\e97a";
$icon-risk-assesment: "\e97b";
$icon-subscription: "\e97c";
$icon-upload-documents: "\e97d";
$icon-video-kyc: "\e97e";
$icon-video: "\e97f";

