@import "src/styles/variables";

.account-manager__container {
  .account-manager__header {
    padding: 3rem 2rem 1rem;
  }

  .account-manager__table {
    padding: 0rem 2rem 3rem;

    .filter-btn {
      left: 16.3rem;
      bottom: 3rem;

      width: 86px;
      height: 32px;
      background: $white;
      border: 0.5px solid $input-border;
      border-radius: 10px;
      color: black;
    }

    .account-manager__form {
      width: 816px;
      height: 189px;
      border: 0.5px solid $input-border;
      background: $white;
      box-shadow: 0px 10px 20px $dark-primary-color;
      border: 0.5px solid $input-border;
      border-radius: 16px;
      opacity: 1;
      display: flex;
    }

    .filter_reset {
      width: 67px;
      height: 32px;
      background: $input-border;
      border-radius: 10px;
      opacity: 1;
    }

    .filter_apply {
      width: 68px;
      height: 32px;
      background: $primary-color;
      border-radius: 10px;
      opacity: 1;
    }

    .account-manager__list-table {
      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-table {
              .ant-table-container {
                .ant-table-content {
                  table {
                    .ant-table-tbody {
                      .ant-table-row {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-popover-content {
  margin-top: 5px;

  .ant-popover-inner {
    border-radius: 16px;

    .styled-modal-btn {
      h3 {
        padding-top: 10px;
      }
    }
  }

  .ant-popover-title {
    display: none;
  }

  .ant-popover-inner-content {
    border-radius: 16px;
    width: 500px;
    border: 0.5px solid $input-border;
    background: $white;
    box-shadow: 0px 10px 20px $dark-primary-color;
    border: 0.5px solid $input-border;
    opacity: 1;
  }

  .dropdown-field {
    margin-bottom: 0;
    margin-top: 15px;

    .dropdown-field__title {
      padding-left: 22px;
      text-transform: capitalize;
    }

    .ant-select {
      padding-left: 15px;
    }

    .ant-select-selector {
      overflow: auto;
    }
  }

  .account-manager__reset-btn {
    position: relative;
    right: 20%;
    top: 30%;
    background: $primary-color;
  }

  .account-manager__submit-btn {
    top: 30%;

    &.ant-btn.ant-btn-primary {
      background: $input-border;
    }
  }
}

.filterCalc {
  position: absolute;
  top: 91%;
  left: 8%;
}

.filter-popup {
  padding-bottom: 1rem;
}