.subscription__form {
    .dropdown-field {
        font-size: 15px;

        .ant-select {
            height: 56px;

            .ant-select-selector {
                height: 100%;
            }
        }
    }

    .ant-list {
        .ant-list-item {
            font-size: 15px;
        }
    }

    .confirm-btn {
        border-top: 2px solid #2AB8F5 !important;
    }
}