@import "src/styles/variables";

.customer-profile__container {
  color: #303030;

  .customer-profile__header {
    padding: 3rem 2rem 0rem;
  }

  .customer-profile__body {
    .customer-profile__body-basic-details {
      margin: 1rem 0;
      position: relative;

      .ant-col {
        padding: 0 1rem;
      }

      .kyc-video__container {
        margin: 1rem;
        width: 100%;
        height: fit-content;
        position: relative;

        video {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 30px;
          cursor: pointer;
        }

        .kyc-video--mask {
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg,
              #21202000 0%,
              #2120206e 77%,
              #212020 100%);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-radius: 30px;
        }

        .upload-component {
          position: absolute;
          top: .5rem;
          right: 1rem;

          .anticon {
            background: #323232;
            color: white;
          }
        }

        .kyc-video--play-btn {
          width: 50px;
          height: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }

        .kyc--status {
          text-align: center;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .subscription-details {
        padding: 2rem;
        background: #ffa417;
        box-shadow: 0px 10px 20px #1c416d0d;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;

        .subscription {
          margin: 0;
          font-size: 24px;
          font-weight: 500;
        }

        .subscription-upgrade {
          color: #fff;
          background: #ffffff1a;
          border: 1px solid #ffffff;
          border-radius: 14px;
        }
      }
    }

    .customer-profile__body-chama-kyc-details,
    .customer-profile__body-kyc-details {
      padding: 1rem;
      margin: 1rem 1rem 3rem;
      display: flex;
      background: #ffffff;
      box-shadow: 0px 10px 20px #1c416d0d;
      border-radius: 30px;

      .kyc-document-details__header {
        padding-bottom: 1rem;

        .kyc-document-details--title {
          margin: 0;
          display: inline-flex;
          align-items: center;
          font-size: 24px;
          font-weight: 500;

          .title-icon {
            margin-right: 1rem;
            padding: 1rem;
            width: 50px;
            height: 50px;
            display: flex;
            border-radius: 50px;
            background-color: #ffd22e1a;

            img {
              width: 25px;
            }
          }
        }
      }
    }

    .customer-profile__body-chama-kyc-details {

      .subtitle {
        color: #B2B4B5;
        font-size: 14px;
        margin-bottom: .25rem;
      }

      .value {
        font-weight: 600;
        font-size: 16px;
      }

      .title-icon {
        padding: .75rem !important;
        background-color: #5c4e791a !important;
      }
    }

    .customer-profile__body-risk-assessment {
      margin: 1rem;

      .customer-profile__body-risk-assessment--title {
        margin: 0 0 1rem 0;
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
}

.customer-profile--save {
  padding: 1rem 0 0 0;
  text-align: end;
  color: #707070;
  font-size: 13px;

  button {
    margin: 1rem 0;
    width: 155px !important;
    height: 45px;
    border: none;
    border-radius: 10px;
    background-color: #002f87;

    &:disabled {
      opacity: 0.5;
      color: #fff;
    }
  }
}

.enter-detail{
  &__container {
    &--description{
      color: $primary-black;
      margin-bottom: 20px;
    }

    &--wrong-id{
      
      display: flex;
      margin-top: 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
     color:  $primary-color;

     &--link{
      cursor: pointer;
      color: $link-color;
      font-weight: 600;
      font-size: 16px;
     }
    }

    button {
        margin-top: 32px;
        width: 100% !important;
        height: 48px;
        border: none;
        border-radius: 18px;
        font-size: 16px;
        background: $primary-color;
        color: #fff;
        display: inline;

        &:hover, &:focus {
          background: $primary-color;
          color: #fff;
        }
    }
  }
}