@import "src/styles/variables";

.terms-and-conditions-modal {
    z-index: 100;

    .ant-modal-header{
        display: none;
    }

    .ant-modal-body{
    height: 80vh;
    overflow-y: scroll;
    }
    .ant-modal-footer {
        display: flex !important;
        justify-content: center;
        border-top: 1px solid #002F87;

        button {
            width: 130px !important;
            height: 45px !important;
            background: #002F87;
            border-radius: 15px;
            font-size: 16px;
            font-weight: 600;

            &:hover{
                box-shadow: 0px 3px 6px #00000029;
            }
        }

        button:nth-child(1) {
            background-color: #fff;
            color: #e73f3f;
            border: 1px solid #e73f3f;
        }
    }
}