.sidebar__container {
  margin-top: 50px;
  height: calc(100% - 50px);

  .sidebar__logo {
    width: 100%;
    height: 75px;
    background: url("./../../../assets/icons/logo\ -\ svg/vuka-logo-dark.svg")
      no-repeat top center;
    background-size: 80px 25px;
    border-right: 1px solid silver;
  }

  .sidebar__items {
    padding: 0 1rem;
    width: 100%;
    height: fit-content;
    min-height: calc(100% - 25px);
    border-right: 1px solid silver;
    position: relative;
    display: flex;
    flex-direction: column;

    .sidebar__items--item {
      margin: 0 auto 1rem auto;
      padding: 0 0 0 1.5rem;
      width: 200px;
      height: 50px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 15px;
      color: #002f87;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      transition: all 500ms, color 500ms;

      img {
        margin: 0 0.5rem 0 0;
        width: 20px;
      }

      &.ant-btn {
        border: none;

        &::after,
        &::before {
          display: none;
        }
      }

      
    }
    
    .sidebar__profile-container{
      margin-top: auto;
      position: relative;
     
      .user-card {
          position: absolute;
          bottom: 1rem;
          left: 0;
          right: 0;
          padding: 1.4rem;
          margin: auto;
          cursor: pointer;
      
          .user-wrapper {
            margin-right: 1.75rem;
      
            img {
              width: 45px;
              height: 48px;
            }
          }
      
          .user-name {
            margin-top: 0.25rem;
            text-align: left;
            font: normal normal medium 14px/16px SF Pro Display;
            font-size: 13px !important;
            color: #002F87;
            opacity: 1;
          }
      
          .role {
            margin-top: -0.5rem;
            font-size: 12px;
            font-weight: normal;
            color: #002F87;
            opacity: 1;
            text-transform: capitalize;
          }
        }
      
    }

    .sidebar__items--item:nth-child(1).active ~ .sidebar__items--item.animator {
      top: 0px;
    }

    .sidebar__items--item:nth-child(2).active ~ .sidebar__items--item.animator {
      top: calc(1 * 66px);
    }

    .sidebar__items--item:nth-child(3).active ~ .sidebar__items--item.animator {
      top: calc(2 * 66px);
    }

    .sidebar__items--item:nth-child(4).active ~ .sidebar__items--item.animator {
      top: calc(3 * 66px);
    }

    .sidebar__items--item:nth-child(5).active ~ .sidebar__items--item.animator {
      top: calc(4 * 66px);
    }

    .sidebar__items--item:nth-child(6).active ~ .sidebar__items--item.animator {
      top: calc(5 * 66px);
    }

    .sidebar__items--item:nth-child(7).active ~ .sidebar__items--item.animator {
      top: calc(6 * 66px);
    }

    .sidebar__items--item:nth-child(8).active ~ .sidebar__items--item.animator {
      top: calc(7 * 66px);
    }

    .sidebar__items--item:nth-child(9).active ~ .sidebar__items--item.animator {
      top: calc(8 * 66px);
    }

    .sidebar__items--item.animator {
      position: absolute;
      top: 0;
      z-index: -1;
      background: #002f87;
    }

    // .sidebar__items--item::after {
    //     content: "";
    //     width: 0;
    //     height: 50px;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     color: #fff;
    //     border-radius: 13px;
    //     transition: all 500ms;
    //     background: #002F87;
    // }

    .sidebar__items--item.active {
      color: #fff;
    }

    // .sidebar__items--item.active::after {
    //     width: 200px;
    //     color: #fff;
    //     z-index: -1;
    // }
  }

  
}

.sidebar__profile-popover {
  cursor: pointer;
  .ant-popover-content .ant-popover-inner-content {
    width: 335px;
    height: 256px;
  }

  .ant-popover-arrow {
    display: none;
  }
  .popover__user-name-col{
    display: inline-flex;
    justify-content: space-between;
    .popover__user-name{
      font: normal normal 600 20px/26px SF Pro Display;
      letter-spacing: 0px;
      color: #303030;
      opacity: 1;
    }
    .popover--user-edit{
      font: normal normal 600 15px/18px SF Pro Display;
      letter-spacing: 0px;
      color: #707070;
      opacity: 0.8;
      margin-top:0.3125rem;
      margin-right: 0.3125rem;
      &:hover{
        cursor: not-allowed;
      }
    }
  }
  .popover__user-info-col{
    display: inline-flex;
    .popover__user-uid{
      font: normal normal medium 14px/26px SF Pro Display;
      letter-spacing: 0px;
      color: #B2B4B5;
      opacity: 1;
    }
    .popover__user-role{
      margin-left: 0.625rem;
      font: normal normal medium 14px/26px SF Pro Display;
      letter-spacing: 0px;
      color: #B2B4B5;
      opacity: 1;
      text-transform: capitalize;
    }
  }

  .popover__user-email-col{
    display: inline-flex;
    gap: 0.625rem;
    img{
      width: 1.125rem;
      height: 1rem;
      margin-top: 0.125rem; 
    }
    .popover__user-email{
      font: normal normal normal 14px/16px SF Pro Display;
      letter-spacing: 0px;
      color: #303030;
      opacity: 1;
    }
  }
  .popover__user-phone-col{
    margin-top: 0.625rem;
    display: inline-flex;
    gap: 0.625rem;
    img{
      width: 20px;
      height: 18px;
    }
    .popover__user-phone{
      font: normal normal normal 14px/16px SF Pro Display;
      letter-spacing: 0;
      color: #303030;
      opacity: 1;
    }
  }

  .popover__user-divider{
    border-top: 1px solid #303030;
    opacity: 0.3;
    margin-top: 1.125rem;
  }

  .popover__change-password-col{
    display: inline-flex;
    gap: 10px;
    margin-top: 1.175rem;

    img{
      width: 20px;
      height: 18px;
      margin-top: 0.125rem;
    }
    .popover__change-password{
      margin-top: 0.125rem;
      font: normal normal 600 15px/18px SF Pro Display;
      letter-spacing: 0px;
      color: #303030;
      opacity: 1;
    }
  }
  .popover__logout-col{
    display: inline-flex;
    gap: 10px;
    margin-top: 0.625rem;
    img{
      width: 20px;
      height: 18px;
      margin-top: 2px;
    }
    .popover__user-logout{
      margin-top: 2px;
      font: normal normal 600 15px/18px SF Pro Display;
      letter-spacing: 0px;
      color: #303030;
      opacity: 1;
    }
  }
}