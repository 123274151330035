/* Color palette */
$primary-color: #002F87;
$secondary-color: #006FBA;
$light-white: #B2B4B5;
$primary-light-color: #0D494E10;
$primary-black: #303030;
$dark-blue: #22509D;
$darker-blue: #1c416d1a;
$light-grey: #00000015;
$lighter-grey: #707070;
$dark-grey: #8898AA;
$darker-grey: #B2B4B5;
$orange: #FF8872;
$light-red: #EF8E78;
$lighter-red: #e4897b;
$green: #a4cf57;
$light-green: #43d5ae;
$yellow: #ffa417;
$success-color: #43D5AE;
$danger-color: #FE0000;
$warning-color: #F3B21B;
$body-bg: #F5F8FA;
$table-bg: #F7FAFC;
$input-border: #b2b4b5;
$link-color: #2AB8F5;
$date-picker-bg: #E9E9E9;
$buy-share:#FFA417;
$sell-share:#2AB8F5;
$white: #FFFFFF;
$light-white: #ffffffcc;
$dark-primary-color: #1c416d0d;

/* Shadows */
$primary-shadow: 0 8px 22px #0000001A;
$secondary-shadow: 0px 3px 6px #2C28281C;


/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;