@import "./../../../../../styles/variables";

.holdings__container {
  .holdings {
    margin: 1rem 2rem;
    height: fit-content;
    min-height: 7.5rem;
    background-color: $primary-color;
    border-radius: 1.875rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 0.18rem solid $link-color;
    box-shadow: 0px 1.87rem 1.87rem $darker-blue;
    @media only screen and (max-width: 768px) {
      margin: 0.5rem 0.5rem !important;
      width: 95% !important;
      text-align: center;
    }

    .holdings-data {
      padding: 0 0 0 2%;
      width: calc(100% / 6);
      min-width: 100px;

      .holdings-data--header-title {
        color: $light-white;
        font-size: 15px;
        @media only screen and (max-width: 768px) {
          font-size: 12px !important;
          text-align: center;
        }
      }

      .holdings-data--header-value {
        color: $white;
        font-size: 22px;
        font-weight: 600;
        @media only screen and (max-width: 768px) {
          font-size: 15px !important;
          text-align: center;
        }
      }
    }
  }

  .holdings__data {
    margin: 2rem 0;
    font-size: 16px;

    .ant-tabs.holdings-tab {
      .ant-tabs-nav {
        padding: 0;
      }

      .holdings-tab--actions {
        padding: 1rem 0;

        .buy {
          margin-right: 1rem;
          padding: 0 1.5rem;
          color: $buy-share !important;
          border-color: $buy-share !important;
        }

        .sell {
          padding: 0 1.5rem;
          color: $sell-share !important;
          border-color: $sell-share !important;
        }
      }
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 16px;
    }

    .ant-tabs.ant-tabs-top {
      .ant-tabs-nav {
        margin: 0 3rem 2rem 3rem;
      }

      .ant-tabs-content-holder {
        margin: 0 3rem 3rem 3rem;
      }
    }
  }
  .holdings__auto-dividend--container{
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 4.25rem;
    height: 0.0625rem;
    gap: 0.625rem;
    margin-right: 18.125rem;
    .holdings__auto-dividend--heading{
      font-weight: bold;
      font-size: 1rem;
    }
    .ant-switch{
      min-width: 38px;
    }
    .ant-switch-checked{
      background-color: $light-green;
    }
  }
  .holdings__buy-shares--container{
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 4.65rem;
    height: 0.0625rem;
    gap: 0.625rem;
    margin-right: 9.375rem;
    .holdings__buy-shares--btn{
      background: $white 0% 0% no-repeat padding-box;
      font: normal normal normal 17px/26px SF Pro Display;
      border: 1px solid $yellow;
      border-radius: 0.625rem;
      opacity: 1;
      color: $yellow;
      padding: 0 1.25rem 0 1.25rem;
    }
  }

  .holdings__sell-shares--container{
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 4.65rem;
    height: 0.0625rem;
    gap: 0.625rem;
    margin-right: 0.9375rem;
    .holdings__sell-shares--btn{
      background: $white 0% 0% no-repeat padding-box;
      font: normal normal normal 17px/26px SF Pro Display;
      border: 1px solid $link-color;
      border-radius: 0.625rem;
      opacity: 1;
      color: $link-color;
      padding: 0 1.25rem 0 1.25rem;
    }
  }
}


.holdings{

  &__more-icon {
    color: red;
  
    &.ant-popover {
      padding-top: 0;
    }
  
    .ant-popover-content {
      margin-top: 0;
  
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner-content {
        background: $white;
        border-radius: 20px !important;
        box-shadow: 0px 10px 20px $darker-blue;
        width: 195px;
        border: none;
        padding: 0;
        color: $light-red;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        padding: 24px;
      }
    }

    &--icon {
      cursor: pointer;

      &:hover{
        svg path {
          fill: $primary-black;
        }
      }
    }
  }

  &__reject-confirmation {
    .ant-modal {
      &-content {
        border-radius: 30px;
      }
      &-body {
        padding: 40px;
      }
    }

    &--container {
      .ant-divider-horizontal {
        margin: 0;
        margin-bottom: 12px;
      }

      &--header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
  
        &--title {
          color: $primary-black;
          font-size: 24px;
          font-weight: bold;
        }
      }

      &--warning {
        font-size: 15px;
        margin-bottom: 19px;
      }

      &--data {
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
        &--key {
          color: $lighter-grey;
          font-size: 15px;
        }

        &--name {
          text-transform: capitalize;
        }
        &--value {
          color: $primary-black;
          font-size: 15px;
        }
      }

      &--submit-sec {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        &--cancel-btn {
          &.ant-btn{
            border-radius: 18px;
            background-color: $darker-grey;
            border: none;
            color: $white;
            font-size: 17px;
            font-weight: 600;
            padding: 0;
            height: 100%;
            width: 100%;

            &:hover, &:focus {
              background-color: $darker-grey;
              color: $white;
            }

            span {
              padding-top: 14px;
              padding-bottom: 14px;
            }
          }
        }

        &--reject-btn {
          &.ant-btn{
            border-radius: 18px;
            background-color: $orange;
            border: none;
            color: $white;
            font-size: 17px;
            font-weight: 600;
            padding: 0;
            height: 100%;
            width: 100%;

            &:hover, &:focus {
              background-color: $orange;
              color: $white;
            }

            span {
              padding-top: 14px;
              padding-bottom: 14px;
            }
          }
        }
      }
    }
  }
}

.Purchased {
  color: $green;
}

.Pending,
.Hold {
  color: $yellow;
}

.Partially {
  color: $light-green;
}

.Failure,
.Cancelled {
  color: $lighter-red;
}
.Fulfilled {
  color: $green;
}
