@import "src/styles/variables";

.otp-modal {
	.ant-modal-content {
		border-radius: 30px;

		.ant-modal-close {
			display: none;
		}

		.ant-modal-header {
			padding: 2rem;
			border-bottom: none;
			border-radius: 30px;

			.ant-modal-title {
				font-size: 24px;
				font-weight: bold;
			}
		}

		.ant-modal-footer {
			display: none;
		}
	}
}

.o-t-p-form {
	margin: auto;
	width: 80%;

	h1 {
		font-weight: bolder;
	}

	.otp__container {

		.resend__wrapper {
			margin-top: 1rem;
			display: flex;
			color: #b2b4b5;
			justify-content: flex-end;

			&.disable-resend {
				cursor: not-allowed;
			}
		}
	}

	.otp__wrapper {

		input {
			width: 3rem !important;
			border: none;
			border-bottom: 1px solid #b2b4b5;

			&:focus-visible {
				outline: none !important;
			}
		}
	}

	.submit-btn {
		display: flex;
		justify-content: center;
		margin-top: 3rem;

		.ant-btn.ant-btn-primary {
			border-radius: 12px;
			background: #002F87;
			border: none;
			width: 60% !important;
			height: 46px;
			text-align: center;

			&:disabled {
				color: #fff !important;
			}
		}
	}
}