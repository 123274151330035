@import "./../../../styles/_variables";

.login {
    
    form {
        width: 60%;
        margin-left: 5vw;
        h1 {
            font-size: 28px;
            margin-bottom: 2rem;
            margin-top: 5rem;
            font-weight: 500;
            text-align: center;
            color: $primary-color;
        }

        .input-field__wrapper{
            margin: 1rem 0;

            input{
                height: 42px;
            }
        }

        .submit-btn {
            margin-top: 3rem !important;
            display: flex;
            justify-content: center;
            .ant-btn.ant-btn-primary {
                border-radius: 12px;
                background: $primary-color;
                border: none;
                border-top: 3px solid $link-color;
                width: 60% !important;
                height: 46px;
                text-align: center;
            }
        }
    
        .login-link {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}