.app__container {
  display: flex;
  .nav__container {
    width: 270px;
    height: 100vh;

    overflow-y: scroll;
  }

  .route__container {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .route__container > *:not(.page-header__container) {
    animation: bloomIn 1000ms ease forwards;
  }

  .route__container::-webkit-scrollbar {
    width: 10px;
    background: white;
  }

  .route__container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(192, 192, 192);
  }

  .nav__container::-webkit-scrollbar,
  .route__container::-webkit-scrollbar-track,
  .route__container::-webkit-scrollbar-track-piece,
  .route__container::-webkit-scrollbar-button {
    display: none;
  }
}

@keyframes bloomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
