@import "src/styles/variables";

.payments {
  &__header {
    padding: 3rem 2rem 1rem;
  }
  &__table {
    padding: 0rem 2rem 3rem;
  }

  &__more-icon {
    color: red;

    &.ant-popover {
      padding-top: 0;
    }

    .ant-popover-content {
      margin-top: 0;

      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner-content {
        background: $white;
        border-radius: 20px !important;
        box-shadow: 0px 10px 20px $darker-blue;
        width: 195px;
        border: none;
        padding: 0;
        color: $light-red;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        padding: 24px;
      }
    }

    &--icon {
      cursor: pointer;

      &:hover {
        svg path {
          fill: $primary-black;
        }
      }
    }
  }

  &__reject-confirmation {
    .ant-modal {
      &-content {
        border-radius: 30px;
      }
      &-body {
        padding: 40px;
      }
    }

    &--container {
      .ant-divider-horizontal {
        margin: 0;
        margin-bottom: 12px;
      }

      &--header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;

        &--title {
          color: $primary-black;
          font-size: 24px;
          font-weight: bold;
        }
      }

      &--warning {
        font-size: 15px;
        margin-bottom: 19px;
      }

      &--data {
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
        &--key {
          color: $lighter-grey;
          font-size: 15px;
        }

        &--name {
          text-transform: capitalize;
        }
        &--value {
          color: $primary-black;
          font-size: 15px;
        }
      }

      &--submit-sec {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        &--cancel-btn {
          &.ant-btn {
            border-radius: 18px;
            background-color: $darker-grey;
            border: none;
            color: $white;
            font-size: 17px;
            font-weight: 600;
            padding: 0;
            height: 100%;
            width: 100%;

            &:hover,
            &:focus {
              background-color: $darker-grey;
              color: $white;
            }

            span {
              padding-top: 14px;
              padding-bottom: 14px;
            }
          }
        }

        &--reject-btn {
          &.ant-btn {
            border-radius: 18px;
            background-color: $orange;
            border: none;
            color: $white;
            font-size: 17px;
            font-weight: 600;
            padding: 0;
            height: 100%;
            width: 100%;

            &:hover,
            &:focus {
              background-color: $orange;
              color: $white;
            }

            span {
              padding-top: 14px;
              padding-bottom: 14px;
            }
          }
        }
      }
    }
  }
}
