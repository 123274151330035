.search-bar__container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: start;

    .search-bar--input {
        margin: 0 0.5rem 0 0;
        position: relative;
        width: fit-content;

        input {
            padding: .25rem .5rem .25rem 2rem;
            width: 250px;
            height: 32px;
            background: #fff;
            border: 0.5px solid #B2B4B5;
            background: #FFFFFF;
            border: 0.5px solid #B2B4B5;
            border-radius: 10px;
            outline: none !important;

            &::placeholder,
            &:-ms-input-placeholder,
            &::-ms-input-placeholder {
                color: #B2B4B5;
            }
        }

        .anticon {
            font-size: 16px;
            color: #002F87;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
    }

    .search-bar--filter {
        margin: 0 0.5rem 0 0;

        .ant-btn {

            &:focus,
            &:hover {
                color: #303030;
            }
        }

        .ant-dropdown-trigger {
            width: 86px;
            height: 32px;
            background: #FFFFFF;
            border: 0.5px solid #B2B4B5;
            border-radius: 10px;
            background-image: url('./../../.././assets/icons/multi\ color\ icons\ -\ svg/filter.svg');
            background-position: 10px;
            background-size: 12px;
            background-repeat: no-repeat;
            padding: 4px 15px 4px 30px;

            img {
                margin: 0 .25rem 0 0;
                width: 12px;
                height: 12px;
            }

            &.ant-dropdown-open {
                background: #002F87;
                color: #fff;
                border: 0.5px solid #B2B4B5;
                background-image: url('./../../.././assets/icons/multi\ color\ icons\ -\ svg/filter white.svg');
                background-position: 10px;
                background-size: 12px;
                background-repeat: no-repeat;
                padding: 4px 15px 4px 30px;
            }
        }
    }

}

.filter__container {
    width: 520px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px #1C416D0D;
    border: 0.5px solid #b2b4b55b;
    border-radius: 16px;

    .filter--header {
        padding: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #b2b4b55b;

        .filter--header-title {
            font-size: 18px;
            font-weight: bold;
        }

        .filter--header-actions {
            display: flex;

            button {
                margin-right: 1rem;
                background: #B2B4B5;
                border-radius: 10px;
                color: #fff;

                &:last-child {
                    margin-right: 0;
                }

                &.primary {
                    background: #002F87;
                }
            }
        }
    }
}