@import "src/styles/variables";

.customer-details {
  h1 {
    font-weight: bolder;
    font-size: 24px;
  }

  .details__wrapper {
    background-color: rgba($color: $light-white, $alpha: 0.1);
    padding: 2rem 2rem 1rem 2rem;
    border-radius: 10px;

    .ant-col.ant-col-12 {
      margin-bottom: 1.5rem;

      label {
        color: $primary-black;
      }

      p {
        margin: auto 0;
        color: $primary-black;
        font-weight: bolder;
      }
    }

    .date-picker-field {
      margin: 0 1rem 0 0;

      .ant-picker {
        margin: 0;
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    .ant-btn.ant-btn-primary {
      width: 70% !important;
      text-align: center;
    }
  }

  .link {
    text-align: center;
    margin: 1rem 0;

    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}