@import "./../../../styles/variables";

.date-picker-field {

    .date-picker-field__title {
        margin-bottom: 5px;
    }

    .ant-picker {
        width: 100%;
        height: 40px;
        border-radius: 12px;
        padding-left: 20px !important;
        background: white;
        border: 1px solid #b2b4b5 !important;

        .ant-picker-active-bar {
            background: $primary-color !important;
        }

    }

}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #002e8533 !important;
}

.ant-picker-cell-inner {

    &::after,
    &::before,
    span {
        visibility: hidden !important;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #002e8533 !important;
}