@import "src/styles/variables";

.refer {
  .refer__header {
    padding: 48px 32px 16px;
  }

  .refer__container {
    width: 100%;
    background-image: url("./../../../assets/images/refer.svg");
    background-size: 282px 262px;
    background-position: center -32px;
    padding-top: 218px;
    background-repeat: no-repeat;
    position: relative;
    top: -62px;
    z-index: -1;
    display: flex;
    justify-content: center;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 525px;
      margin-top: 40px;

      &__invite-friends-description {
        color: $primary-color;
        max-width: 327px;
        text-align: center;
        line-height: 22px;
        margin: 0;
      }

      &__mail-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 24px;
        padding: 24px;
        background-color: $white;
        box-shadow: 0px 30px 30px $darker-blue;
        border-radius: 30px;

        &__field-wrapper {
          display: flex;
          flex-direction: column;

          &__email-field {
            height: 180px;
            min-height: 180px;
            border-radius: 16px;
            border: 1px solid $darker-grey;
            padding: 18px;
            font-size: 18px;
            line-height: 26px;
            color: $primary-black;

            &::-webkit-resizer {
              display: none;
            }
          }

          .input__error {
            height: 22px;
          }

          .error-border {
            border: 1px solid $danger-color;
          }
        }

        &__copy-link-btn {
          &.button-component {
            /* As the previous override using !important need to use !important */
            color: $primary-color !important;
            border: 1px solid $primary-color !important;
          }
        }

        .button-component {
          /* As the previous override using !important need to use !important */
          border-radius: 18px !important;
          height: 48px;
          font-size: 17px;
          line-height: 26px;
        }
      }
    }
  }

  .link-icon {
    background-image: url("./../../../assets/icons/link-chain-icon.svg");
    background-position: center;
    width: 20px;
    height: 20px;
  }
}
