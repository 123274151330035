.risk-assessment__container {
    padding: 1rem;
    background-color: hsla(0, 0%, 75%, 0.3);
    border-radius: 30px;

    .risk-assessment {
        margin: 2rem 0;

        &::marker {
            font-size: 18px;
            font-weight: 500;
        }

        .risk-assessment--question {
            margin: 1rem 0;
            font-size: 18px;
            font-weight: 500;
        }
    }
}