.reset-password {

    form {
        width: 60%;
        margin-left: 5vw;
        transition: 1s;

        h1 {
            margin-top: 4rem;
            margin-bottom: 2rem;
            font-weight: bolder;
        }

        .helper-text {
            margin: 2rem auto;
            width: 400px;
        }

        .submit-btn {
            margin: 5rem auto !important;
            display: flex;
            justify-content: center;

            .ant-btn.ant-btn-primary {
                width: 60% !important;
                border-top: 2px solid #2AB8F5;
                text-align: center;
            }
        }
    }
}