.wallet--container {
  padding: 1rem 2rem;

  .history__container {
    padding: 2rem 1rem;

    @media only screen and (max-width: 768px) {
      width: 120% !important;
      padding: 0rem 0rem !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 16px;
    }

    .ant-collapse {
      // padding: 1rem;
      border: 1px solid #e9f8fe;
      border-radius: 30px;
      background-color: #fff;
      pointer-events: none;

      .ant-collapse-item {
        border: none;
        border-radius: 30px;

        .ant-collapse-header {
          padding: 1.5rem 2rem;
        }

        .wallet-history--item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .content {
            display: flex;
            align-items: center;

            .content-icon {
              padding: 0 1rem;

              img {
                width: 40px;
                height: 40px;
              }
            }

            .content-text {
              .content-text--title {
                margin: 0;

                span {
                  font-size: 16px;
                  font-weight: 600;
                  padding: 0 0.5rem;
                }

                .content-text--expand {
                  font-size: 14px;
                  font-style: italic;
                  font-weight: normal;
                  color: #b2b4b5;
                  pointer-events: all;
                }
              }

              .content-text--subtitle {
                margin: 0.5rem;
              }
            }
          }

          .actions {
            font-size: 16px;
            font-weight: 600;
          }

          .actions.success {
            color: #43d5ae;

            &::before {
              content: "+ ";
            }
          }

          .actions.danger {
            color: #ff8872;

            &::before {
              content: "- ";
            }
          }
        }

        .ant-collapse-content {
          border-top: none;
          background-color: hsla(197, 91%, 95%, 0.5);

          .panel-content {
            margin: auto;
            width: 100%;
            max-width: 800px;
            display: flex;

            .panel-content--details {
              padding: 0 2rem;
              width: 25%;

              p {
                margin: 0;
              }

              .panel-content--details--title {
                font-size: 14px;
              }

              .panel-content--details--value {
                margin: 0.5rem 0;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
        }
      }

      .footer_transaction {
        .ant-col.ant-col-24 {
          text-align: center;
          padding-top: 0.5rem;

          background-color: #f4fbfe;
          display: flex;

          label {
            padding-left: 10rem;
            padding-bottom: 20px;
          }

          p {
            position: absolute;
            left: 40%;
            margin: auto 0;
            bottom: 40%;

            font-weight: bolder;
            text-align: left;
          }
        }
      }
    }

    // .ant-tabs.ant-tabs-top {
    //   .ant-tabs-nav {
    //     margin: 0 0 2rem 0;
    //   }
    // }

    .loading {
      .ant-space {
        margin: 5rem 0 0 0;
        width: 100%;
      }
    }
  }
}

.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header>.arrow {
  color: initial !important;
  cursor: auto !important;
}

.ant-collapse-item.ant-collapse-item-active {
  .ant-collapse-header {
    .wallet-history--item {
      .content {
        .content-text {
          .content-text--title {
            .content-text--expand::before {
              content: "Hide ";
            }
          }
        }
      }
    }
  }
}

.ant-collapse-item {
  .ant-collapse-header {
    .wallet-history--item {
      .content {
        .content-text {
          .content-text--title {
            .content-text--expand::before {
              content: "View ";
            }
          }
        }
      }
    }
  }
}