.ant-modal.styled-modal {
  width: 600px !important;
  top: 45px !important;

  .ant-modal-content {
    border-radius: 30px;

    .ant-modal-close {
      top: 16px;
      right: 1rem;

      .ant-modal-close-x {
        .anticon {
          color: #fff;
          font-size: 14px;
        }
      }
    }

    .ant-modal-header {
      padding: 2rem 3rem 6rem;
      border-radius: 30px 30px 0 0;

      .ant-modal-title {
        color: #fff;

        h2 {
          margin: 0 0 1rem 0;
          color: #fff;
          font-size: 24px;
          font-weight: bold;
        }

        div {
          display: flex;
          align-items: center;
          min-width: 250px;
          width: 65%;
          font-size: 14px;

          p {
            margin: 0.5rem 1rem 0.5rem 0;
          }

          img {
            margin: 0 5px;
            width: 15px;
            height: 15px;
          }
        }

        .balance {
          text-align: end;
          margin-left: auto;
          font-weight: 600;
        }

        .buy-share__input-container {
          display: inline-flex;
          width: 100%;

          .buy-share__input {
            width: 130px;
            outline: none;
            border-width: 0 0 0.0625rem !important;
            border-color: #FFFFFF;
            background: transparent;
            color: #FFF;
            font-size: 1.125rem;
            margin-right: 1.25rem;
          }

        }

        .shares__details--unitContainer {
          width: 50px !important;
          min-width: 150px !important;
          box-sizing: border-box;
          margin: 0;
          padding: 0.25rem;
          color: rgba(0, 0, 0, 0.85);
          font-size: 0.875rem;
          font-variant: tabular-nums;
          line-height: 1.5715;
          list-style: none;
          font-feature-settings: 'tnum', "tnum";
          display: inline-block;
          background: #FFFFFF28 !important;
          border-radius: 0.625rem;

          .ant-radio-button-wrapper {

            font: normal normal medium 13px/15px SF Pro Display;
            background: none;
            border: none;

            &:first-child {
              border-radius: 0.625rem;
              border: none;
            }

            &:last-child {
              border-radius: 0.625rem;
              border: none;
            }

            &:hover {
              background-color: #FFF;
              color: #000;
              --antd-wave-shadow-color: #FFF;
            }
          }

          .ant-radio-button-wrapper-checked {
            background: #FFF;
            border: none;
            color: #000;
            --antd-wave-shadow-color: transparent;

          }

          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            border: none;
            background-color: transparent;
          }

          label.ant-radio-button-wrapper::before {
            display: none;
          }

        }
      }
    }

    .ant-modal-body {
      padding: 7rem 1.5rem 1.5rem;
      border-radius: 0 0 30px 30px;
      position: relative;

      .main-content {
        padding: 1.5rem;
        width: calc(100% - 6rem);
        min-height: 140px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        text-align: center;
        border-radius: 30px;
        box-shadow: 0px 30px 30px #1c416d0f;

        .main-content__amount-container {
          display: inline-flex;
          gap: 20px;
        }

        .title {
          margin: 0;
          font-size: 16px;
        }

        h1 {
          margin: 0.5rem 0;
          font-size: 32px;
          font-weight: 600;
        }

        .ant-skeleton {
          margin: 1rem 0;
        }
      }

      .wallet-form__payment-method {
        display: flex;
        align-items: center;
        font-size: 16px;

        &.justify-between {
          justify-content: space-between;
        }

        p {
          margin: 0.5rem 1rem 0.75rem 0;
        }

        .m-pesa-icon {
          width: 65px;
          height: 25px;
        }

        .bank-icon__container {
          padding: 0.4rem 0.4rem 0.4rem 0.5rem;
          border: 0;
          border-radius: 100%;
          background-color: #ffa31a31;
          text-align: center;

          img {
            width: 80%;
          }
        }
      }

      .content-template {
        padding: 0 2rem;

        .ant-divider-horizontal {
          margin: 0;
        }

        .input-field__wrapper {
          // margin: 0 2rem;

          input {
            height: 50px;
          }
        }

        .payment_form__footer {
          margin: 1rem auto 0 auto;
          width: fit-content;

          img {
            margin: 0 0.5rem;
            width: 15px;
            height: 15px;
          }
        }
      }

      .footer {
        text-align: center;
        padding: 2rem 2rem 0rem;

        .terms-acceptance-label {
          font-size: 16px;
        }

        .styled-modal-btn {
          button {
            margin: 1rem 0 0 0;
            background-color: #303030;

            span {
              font-size: 17px;
              font-weight: 600;
            }
          }
        }

        .wallet_form__footer {
          display: none;
        }

        .payment_form__footer {
          margin: 1rem auto 0 auto;
          width: fit-content;

          img {
            margin: 0 0.5rem;
            width: 15px;
            height: 15px;
          }
        }

        .input__error {
          text-align: left;
        }
      }

      .ant-list {
        margin: auto;
        width: 70%;
        min-width: 350px;

        .ant-list-item {
          font-size: 15px;

          .bold {
            font-weight: bold;
          }

          .danger {
            color: #ff6d3f;

            &::before {
              content: none !important;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }


}

.ant-modal.styled-modal.yellow-bg {
  .ant-modal-header {
    background-color: #ffa417;
  }
}

.ant-modal.styled-modal.blue-bg {
  .ant-modal-header {
    background-color: #2ab8f5;
  }
}

.ant-modal.styled-modal.dark-bg {
  .ant-modal-header {
    background-color: #002f87;
  }

  .ant-modal-body {
    .main-content {
      .wallet-form {
        .wallet-form--field {
          border: none;
          width: 100%;
          height: 60px;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          outline: none;

          &::placeholder {
            color: #b2b4b5;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }

    .content-template {
      width: 100%;
    }

    .footer {
      padding-top: 0;
      padding-bottom: 0;

      .input-field__wrapper {
        margin-bottom: 4rem !important;

        input {
          height: 50px;
        }
      }

      .wallet_form__footer {
        margin: 1rem 0 0 0;
        display: block;
      }
    }
  }
}