.report__container {

    .report__header {
        padding: 3rem 2rem 1rem;
    }

    .report__form {
        margin: 0 2rem;
        padding: 2rem;
        box-shadow: 0px 30px 30px #1C416D0F;
        border-radius: 30px;
    }

    .report__preview {
        margin: 2rem;
        border: 0.5px solid #b2b4b5;
        background: #F7F7F8;
        border: 0.5px solid #B2B4B5;
        border-radius: 30px;
        position: relative;
    }
}