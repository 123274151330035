.ui-modal.ant-modal {

    .ant-modal-content {
        border-radius: 30px;

        .ant-modal-close {
            top: 3rem;
            transform: translate(-50%, -50%);
        }

        .ant-modal-body {
            padding: 2rem 3rem;
            border-radius: 30px;

            h2 {
                font-family: SFProDisplayBold;
                font-weight: 600;
                margin-bottom: 2rem;
            }
        }
    }
}