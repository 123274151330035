@import "icons_variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?drdmy4');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?drdmy4#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?drdmy4') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?drdmy4') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?drdmy4##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vuka-full-logo .path1 {
  &:before {
    content: $icon-vuka-full-logo-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path2 {
  &:before {
    content: $icon-vuka-full-logo-path2;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path3 {
  &:before {
    content: $icon-vuka-full-logo-path3;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path4 {
  &:before {
    content: $icon-vuka-full-logo-path4;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path5 {
  &:before {
    content: $icon-vuka-full-logo-path5;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path6 {
  &:before {
    content: $icon-vuka-full-logo-path6;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path7 {
  &:before {
    content: $icon-vuka-full-logo-path7;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path8 {
  &:before {
    content: $icon-vuka-full-logo-path8;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path9 {
  &:before {
    content: $icon-vuka-full-logo-path9;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path10 {
  &:before {
    content: $icon-vuka-full-logo-path10;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path11 {
  &:before {
    content: $icon-vuka-full-logo-path11;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path12 {
  &:before {
    content: $icon-vuka-full-logo-path12;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path13 {
  &:before {
    content: $icon-vuka-full-logo-path13;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path14 {
  &:before {
    content: $icon-vuka-full-logo-path14;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path15 {
  &:before {
    content: $icon-vuka-full-logo-path15;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path16 {
  &:before {
    content: $icon-vuka-full-logo-path16;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path17 {
  &:before {
    content: $icon-vuka-full-logo-path17;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path18 {
  &:before {
    content: $icon-vuka-full-logo-path18;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path19 {
  &:before {
    content: $icon-vuka-full-logo-path19;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path20 {
  &:before {
    content: $icon-vuka-full-logo-path20;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path21 {
  &:before {
    content: $icon-vuka-full-logo-path21;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path22 {
  &:before {
    content: $icon-vuka-full-logo-path22;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path23 {
  &:before {
    content: $icon-vuka-full-logo-path23;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path24 {
  &:before {
    content: $icon-vuka-full-logo-path24;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path25 {
  &:before {
    content: $icon-vuka-full-logo-path25;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path26 {
  &:before {
    content: $icon-vuka-full-logo-path26;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path27 {
  &:before {
    content: $icon-vuka-full-logo-path27;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path28 {
  &:before {
    content: $icon-vuka-full-logo-path28;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path29 {
  &:before {
    content: $icon-vuka-full-logo-path29;  
    margin-left: -1.8798828125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-full-logo .path30 {
  &:before {
    content: $icon-vuka-full-logo-path30;  
    margin-left: -1.8798828125em;  
    color: rgb(163, 206, 89);
  }
}
.icon-vuka-logo-dark .path1 {
  &:before {
    content: $icon-vuka-logo-dark-path1;  
    color: rgb(38, 58, 128);
  }
}
.icon-vuka-logo-dark .path2 {
  &:before {
    content: $icon-vuka-logo-dark-path2;  
    margin-left: -2.88671875em;  
    color: rgb(38, 58, 128);
  }
}
.icon-vuka-logo-dark .path3 {
  &:before {
    content: $icon-vuka-logo-dark-path3;  
    margin-left: -2.88671875em;  
    color: rgb(38, 58, 128);
  }
}
.icon-vuka-logo-dark .path4 {
  &:before {
    content: $icon-vuka-logo-dark-path4;  
    margin-left: -2.88671875em;  
    color: rgb(38, 58, 128);
  }
}
.icon-vuka-logo-dark .path5 {
  &:before {
    content: $icon-vuka-logo-dark-path5;  
    margin-left: -2.88671875em;  
    color: rgb(163, 206, 89);
  }
}
.icon-vuka-logo-light .path1 {
  &:before {
    content: $icon-vuka-logo-light-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-logo-light .path2 {
  &:before {
    content: $icon-vuka-logo-light-path2;  
    margin-left: -2.9326171875em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-logo-light .path3 {
  &:before {
    content: $icon-vuka-logo-light-path3;  
    margin-left: -2.9326171875em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-logo-light .path4 {
  &:before {
    content: $icon-vuka-logo-light-path4;  
    margin-left: -2.9326171875em;  
    color: rgb(255, 255, 255);
  }
}
.icon-vuka-logo-light .path5 {
  &:before {
    content: $icon-vuka-logo-light-path5;  
    margin-left: -2.9326171875em;  
    color: rgb(163, 206, 89);
  }
}
.icon-bank-details1 .path1 {
  &:before {
    content: $icon-bank-details1-path1;  
    color: rgb(255, 164, 23);  
    opacity: 0.3;
  }
}
.icon-bank-details1 .path2 {
  &:before {
    content: $icon-bank-details1-path2;  
    margin-left: -1em;  
    color: rgb(255, 164, 23);
  }
}
.icon-beneficary .path1 {
  &:before {
    content: $icon-beneficary-path1;  
    color: rgb(93, 78, 123);  
    opacity: 0.3;
  }
}
.icon-beneficary .path2 {
  &:before {
    content: $icon-beneficary-path2;  
    margin-left: -1em;  
    color: rgb(93, 78, 123);
  }
}
.icon-collapse-arrow .path1 {
  &:before {
    content: $icon-collapse-arrow-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-collapse-arrow .path2 {
  &:before {
    content: $icon-collapse-arrow-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.7;
  }
}
.icon-collapse-arrow .path3 {
  &:before {
    content: $icon-collapse-arrow-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.4;
  }
}
.icon-dynamic-wallet---white .path1 {
  &:before {
    content: $icon-dynamic-wallet---white-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-dynamic-wallet---white .path2 {
  &:before {
    content: $icon-dynamic-wallet---white-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-dynamic-wallet---white .path3 {
  &:before {
    content: $icon-dynamic-wallet---white-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-dynamic-wallet---light-blue .path1 {
  &:before {
    content: $icon-dynamic-wallet---light-blue-path1;  
    color: rgb(42, 184, 245);  
    opacity: 0.3;
  }
}
.icon-dynamic-wallet---light-blue .path2 {
  &:before {
    content: $icon-dynamic-wallet---light-blue-path2;  
    margin-left: -1em;  
    color: rgb(42, 184, 245);
  }
}
.icon-dynamic-wallet---light-blue .path3 {
  &:before {
    content: $icon-dynamic-wallet---light-blue-path3;  
    margin-left: -1em;  
    color: rgb(42, 184, 245);
  }
}
.icon-emergency-contact .path1 {
  &:before {
    content: $icon-emergency-contact-path1;  
    color: rgb(164, 207, 87);  
    opacity: 0.3;
  }
}
.icon-emergency-contact .path2 {
  &:before {
    content: $icon-emergency-contact-path2;  
    margin-left: -1em;  
    color: rgb(164, 207, 87);
  }
}
.icon-expand-arrow .path1 {
  &:before {
    content: $icon-expand-arrow-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-expand-arrow .path2 {
  &:before {
    content: $icon-expand-arrow-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.7;
  }
}
.icon-expand-arrow .path3 {
  &:before {
    content: $icon-expand-arrow-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.4;
  }
}
.icon-faq-selected .path1 {
  &:before {
    content: $icon-faq-selected-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-faq-selected .path2 {
  &:before {
    content: $icon-faq-selected-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-faq .path1 {
  &:before {
    content: $icon-faq-path1;  
    color: rgb(38, 58, 128);  
    opacity: 0.3;
  }
}
.icon-faq .path2 {
  &:before {
    content: $icon-faq-path2;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-holdings-selected {
  &:before {
    content: $icon-holdings-selected;     
    color: #fff;
  }
}
.icon-holdings {
  &:before {
    content: $icon-holdings;     
    color: #263a80;
  }
}
.icon-home-selected .path1 {
  &:before {
    content: $icon-home-selected-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-home-selected .path2 {
  &:before {
    content: $icon-home-selected-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-home .path1 {
  &:before {
    content: $icon-home-path1;  
    color: rgb(38, 58, 127);
  }
}
.icon-home .path2 {
  &:before {
    content: $icon-home-path2;  
    margin-left: -1em;  
    color: rgb(38, 58, 127);  
    opacity: 0.3;
  }
}
.icon-notificaction .path1 {
  &:before {
    content: $icon-notificaction-path1;  
    color: rgb(0, 47, 135);  
    opacity: 0.3;
  }
}
.icon-notificaction .path2 {
  &:before {
    content: $icon-notificaction-path2;  
    margin-left: -1em;  
    color: rgb(0, 47, 135);
  }
}
.icon-profile-selected .path1 {
  &:before {
    content: $icon-profile-selected-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-profile-selected .path2 {
  &:before {
    content: $icon-profile-selected-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-profile-selected .path3 {
  &:before {
    content: $icon-profile-selected-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-profile1 .path1 {
  &:before {
    content: $icon-profile1-path1;  
    color: rgb(38, 58, 128);  
    opacity: 0.3;
  }
}
.icon-profile1 .path2 {
  &:before {
    content: $icon-profile1-path2;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);  
    opacity: 0.3;
  }
}
.icon-profile1 .path3 {
  &:before {
    content: $icon-profile1-path3;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-refer-selected .path1 {
  &:before {
    content: $icon-refer-selected-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-refer-selected .path2 {
  &:before {
    content: $icon-refer-selected-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-refer-selected .path3 {
  &:before {
    content: $icon-refer-selected-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-refer-selected .path4 {
  &:before {
    content: $icon-refer-selected-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-refer .path1 {
  &:before {
    content: $icon-refer-path1;  
    color: rgb(38, 58, 128);  
    opacity: 0.3;
  }
}
.icon-refer .path2 {
  &:before {
    content: $icon-refer-path2;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-refer .path3 {
  &:before {
    content: $icon-refer-path3;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-refer .path4 {
  &:before {
    content: $icon-refer-path4;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-report-selected .path1 {
  &:before {
    content: $icon-report-selected-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-report-selected .path2 {
  &:before {
    content: $icon-report-selected-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-report .path1 {
  &:before {
    content: $icon-report-path1;  
    color: rgb(0, 47, 135);  
    opacity: 0.3;
  }
}
.icon-report .path2 {
  &:before {
    content: $icon-report-path2;  
    margin-left: -1em;  
    color: rgb(0, 47, 135);
  }
}
.icon-start-video-kyc {
  &:before {
    content: $icon-start-video-kyc;     
    color: #ed3130;
  }
}
.icon-static-wallet---blue .path1 {
  &:before {
    content: $icon-static-wallet---blue-path1;  
    color: rgb(38, 58, 128);  
    opacity: 0.3;
  }
}
.icon-static-wallet---blue .path2 {
  &:before {
    content: $icon-static-wallet---blue-path2;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-static-wallet---white .path1 {
  &:before {
    content: $icon-static-wallet---white-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-static-wallet---white .path2 {
  &:before {
    content: $icon-static-wallet---white-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-stop-video-kyc {
  &:before {
    content: $icon-stop-video-kyc-path1;  
    color: rgb(237, 49, 48);
  }
}
.icon-stop-video-kyc .path2 {
  &:before {
    content: $icon-stop-video-kyc-path2;  
    margin-left: -1em;  
    color: rgb(237, 49, 48);
  }
}
.icon-stop-video-kyc-box  {
  &:before {
    content: $icon-stop-video-kyc-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-support-selected {
  &:before {
    content: $icon-support-selected;     
    color: #fff;
  }
}
.icon-support {
  &:before {
    content: $icon-support;     
    color: #263a80;
  }
}
.icon-total-investment .path1 {
  &:before {
    content: $icon-total-investment-path1;  
    color: rgb(42, 184, 245);  
    opacity: 0.3;
  }
}
.icon-total-investment .path2 {
  &:before {
    content: $icon-total-investment-path2;  
    margin-left: -1em;  
    color: rgb(42, 184, 245);
  }
}
.icon-total-investment .path3 {
  &:before {
    content: $icon-total-investment-path3;  
    margin-left: -1em;  
    color: rgb(42, 184, 245);
  }
}
.icon-total-investment .path4 {
  &:before {
    content: $icon-total-investment-path4;  
    margin-left: -1em;  
    color: rgb(42, 184, 245);
  }
}
.icon-wallet-selected .path1 {
  &:before {
    content: $icon-wallet-selected-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.3;
  }
}
.icon-wallet-selected .path2 {
  &:before {
    content: $icon-wallet-selected-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-wallet-selected .path3 {
  &:before {
    content: $icon-wallet-selected-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-wallet .path1 {
  &:before {
    content: $icon-wallet-path1;  
    color: rgb(38, 58, 128);  
    opacity: 0.3;
  }
}
.icon-wallet .path2 {
  &:before {
    content: $icon-wallet-path2;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-wallet .path3 {
  &:before {
    content: $icon-wallet-path3;  
    margin-left: -1em;  
    color: rgb(38, 58, 128);
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-bank-details {
  &:before {
    content: $icon-bank-details; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-collapse {
  &:before {
    content: $icon-collapse; 
  }
}
.icon-date-range {
  &:before {
    content: $icon-date-range; 
  }
}
.icon-expand {
  &:before {
    content: $icon-expand; 
  }
}
.icon-ic-info-24px {
  &:before {
    content: $icon-ic-info-24px; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-message {
  &:before {
    content: $icon-message; 
  }
}
.icon-password {
  &:before {
    content: $icon-password; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-proceed {
  &:before {
    content: $icon-proceed; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-refresh-ccw {
  &:before {
    content: $icon-refresh-ccw; 
  }
}
.icon-risk-assesment {
  &:before {
    content: $icon-risk-assesment; 
  }
}
.icon-subscription {
  &:before {
    content: $icon-subscription; 
  }
}
.icon-upload-documents {
  &:before {
    content: $icon-upload-documents; 
  }
}
.icon-video-kyc {
  &:before {
    content: $icon-video-kyc; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
