@import "variables";
@import "helpers";
@import "antOverrides";
@import "style";

/* Fonts */

@font-face {
  font-family: SFProDisplay;
  src: url('../assets/fonts/FontsFreeNetSFProDisplayRegular.ttf') format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: SFProDisplayBold;
  src: url('../assets/fonts/FontsFreeNetSFProDisplayBold.ttf') format("truetype");
  font-weight: bold;
}

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input,
label,
span,
div {
  font-family: SFProDisplay, 'Roboto', sans-serif !important;
}

label {
  color: $primary-black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}