.configuration__container {

    .configuration__header {
        padding: 3rem 2rem 1rem;
    }

    .configuration__body {

        .ant-tabs {
            .ant-tabs-content-holder {
                padding: 0 2rem 3rem;
            }

        }
    }
}