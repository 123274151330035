.unit-price__container {
    .unit-price__form {

        .input-field__wrapper.mb-5 {
            margin-bottom: 0 !important;
        }

        .unit-price__form--actions {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            .ant-btn {
                padding: 0 2rem;
                width: fit-content !important;
            }
        }
    }

    .unit-price__list {
        margin: 2rem 0 0 0;
    }
}